<template>
  <v-container>
    <p v-show="false">{{runSendNotification}}</p>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <h1 style="color: grey">Monitoreo Ambiental</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card
          class="mx-auto"
          max-width="400"
          elevation="10"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <!-- Driscolls -->
                <v-img src="../assets/driscolls.png" contain max-height="60"></v-img>
              </v-list-item-title>
              <v-list-item-subtitle class="text-center mt-3">{{ new Date(environment.timestamp).toLocaleDateString() }} - {{ new Date(environment.timestamp).toLocaleTimeString() }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <v-row align="center">
              <v-col cols="4">
                <v-icon x-large color="red">mdi-thermometer</v-icon>Temp
              </v-col>
              <v-col class="text-h2" cols="8">
                {{environment.temperature}}&deg;C
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <v-icon x-large color="blue">mdi-water-percent</v-icon>Humedad
              </v-col>
              <v-col class="text-h2" cols="8">
                {{environment.humidity}}%
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <v-icon x-large color="green">mdi-cloud-arrow-down-outline</v-icon>Presión
              </v-col>
              <v-col class="text-h4" cols="8">
                {{environment.pressure}}Pa
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <h3 style="color: grey">Notificaciones</h3>
        <v-text-field label="whatsapp: +14155238886 join gas-lips" v-model="whatsapp" :disabled="active == 1 || environment.notification == 1"></v-text-field>
        <v-text-field label="email" v-model="email" :disabled="active == 1 || environment.notification == 1"></v-text-field>
        <v-text-field label="umbral temp" v-model="temp" :disabled="active == 1 || environment.notification == 1"></v-text-field>
        <v-btn @click="activate" color="primary" elevation="8" rounded :disabled="active == 1 || environment.notification == 1">Activar</v-btn>
        <v-btn @click="deactivate" color="primary" elevation="8" rounded class="ml-5" :disabled="active == 0 && environment.notification == 0">Reiniciar</v-btn>
        <h4 style="color: grey" class="mt-5 mb-3">Estatus de notificación</h4>
        <v-chip v-if="environment.notification == 1" color="green" text-color="white">Enviado</v-chip>
        <v-chip v-if="environment.notification == 0" color="orange" text-color="white">Sin enviar</v-chip>
      </v-col>

    </v-row>
  </v-container>
</template>



<script>
import {db} from '../firebase'
import {ref, update} from "firebase/database";
import axios from "axios";
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      email: '',
      whatsapp: '',
      temp: 0,
      active: 0
    }
  },

  computed: {
    ...mapState(['environment']),

    runSendNotification(){
      if(this.active == 1 && this.environment.temperature > this.temp && this.environment.notification == 0) {
        //console.log("Sending email")
        this.sendNotification(this.email)
        this.updateNotificationFlag(1)
        return true
      } else {
        //console.log("Not sending notification")
        return false
      }

    }

    
  },

  methods: {
    ...mapActions(['getEvents','getEnvironment','getMachineStopsActive']),

    activate () {
      this.active = 1
    },

    deactivate () {
      this.active = 0
      this.updateNotificationFlag(0)
    },

    async sendNotification (email) {
      try {
        if(this.email != '') {
          await axios.post('https://anukin-notifications.herokuapp.com/email', {
            "to" : email,
            "from" : "noreply <notifications@anukin.com>",
            "subject" : 'Alerta en cambio de temperatura',
            "html" : `La temperatura rebasó el umbral de <b>${this.temp} °C</b>. La temperatura actual es <b>${this.environment.temperature} °C</b>`
          })
        }
        if(this.whatsapp != ''){
          await axios.post('https://anukin-notifications.herokuapp.com/whatsapp', {
            "toNumber" : '+521'+this.whatsapp,
            "data" : `La temperatura rebasó el umbral de *${this.temp} °C*. La temperatura actual es *${this.environment.temperature} °C*`
          })
        }
        
      } catch (error) {
        console.log(error)
      }
       
    },

    updateNotificationFlag(value){
      update(ref(db, 'environment'), {
        notification : value
      })
    }

  },

  watch: {
    
  },

  created() {
    this.getEvents()
    this.updateNotificationFlag(0)
    this.getEnvironment()
    this.getMachineStopsActive()
  },
}
</script>